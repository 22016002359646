.app.dark{
    background-color: #222;
    color: rgba(156, 156, 156, 1);

    .chartGrid{
        stroke: rgba(228, 228, 228, 0.01);
    }
    .table{
        background-color: #222;

        .tableCell{
            color: gray;
        }
    }

    .navbar{
        color: #999;
        border-color: #333;

        .search{
            border-color: gray;
        }
    }

    .sidebar{
        background-color: #222;
        border-color: #333;

        .top{
            .logo{
                color: #999;
            }
        }
        hr{
            border-color: #333;
        }
        ul{
            li{
                &:hover{
                    background-color: #333;
                }
            }
        }
    }

    .datatable{
        .datagrid{
            color: gray!important;
            border: none;

            .viewButton, .deleteButton, .cellWithStatus {
                color: gray;
                border: none;
            }
            .MuiTablePagination-root, .MuiCheckbox-root{
                color: gray;
            }
            
        }
    }

    input{
        background-color: transparent;
    }
}