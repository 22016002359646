.chart{
    flex: 4;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    color: gray;

    .title{
        margin-bottom: 20px;
    }

    .chartGrid{
        stroke: lightgray;
    }
}