.widget{
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    height: 100px;

    .left, .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title{
            font-weight: bold;
            font-size: 14px;
            color: rgb(160, 160, 160);
        }

        .counter{
            font-size: 28px;
            font-weight: 300;
        }

        .link{
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid gray;
        }

        .percentage{
            display: flex;
            align-items: center;
            font-size: 14px;

            &.positive{
                color: green;
            }
            &.negative{
                color: red;
            }
        }

        .icon{
            font-size: 18px;
            padding: 5px;
            background-color: rgb(128, 70, 128, 0.541);
            border-radius: 5px;
            align-self: flex-end;
        }
    }
}