.single{
    display: flex;
    width: 100%;

    .singleContainer{
        flex: 6;

        .top{
            padding: 20px;
            display: flex;
            gap: 20px;

            .left{
                flex: 2;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                padding: 20px;
                position: relative;

                .editButton{
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 5px;
                    font-size: 12px;
                    color: #7451f8;
                    background-color: #7551f818;
                    cursor: pointer;
                    border-radius: 0px 0px 0px 5px;
                }

                .item{
                    display:flex;
                    gap: 20px;

                    .itemImg{
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                    .details{
                        .itemTitle{
                            margin-bottom: 10px;
                            color: #555;
                        }
                        .detailItem{
                            margin-bottom: 10px;
                            font-size: 14px;

                            .itemKey{
                                font-weight: bold;
                                color: gray;
                                margin-right: 5px;
                            }
                            .itemValue{
                                font-weight: 300;
                            }
                        }
                    }
                }
            }
            .right{
                flex: 4;
            }
        }

        .bottom{
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            padding: 20px;
            margin: 20px;
        }
        .title{
            font-size: 16px;
            color: lightgray;
            margin-bottom: 20px;
        }
    }
}